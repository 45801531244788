<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('pay_usdt') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          to="Record"
        >
          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-btn>
      </v-toolbar>

      <v-sheet
        color="nav_bg"
        class="pa-5 mt-3 text-center rounded-t-xl"
      >
        <div class="d-flex align-center justify-space-between text-body-2 text--disabled">
          <div>{{ $t('fen_yue') }}:</div>
          <div>
            <img
              width="16"
              height="16"
              src="../assets/img/icons/coin.svg"
              class="mx-1 mb-n1"
            >
            {{ agentinfo.assets?.available }} {{ $t('fen') }}
          </div>
        </div>
      </v-sheet>
      

      <v-sheet
        color="#fff"
        class="pa-3 mb-3 rounded-b-xl"
      >
        <div class="text-center">
          <img
            :src="erweima"
            style="width: 120px;"
          >
        </div>
        <v-list
          class="py-0"
        >
          <v-list-item
            ripple
            @click="doCopy(recharge_addr)"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('pay_usdt_address') }}
                <v-btn
                  icon
                  small
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ recharge_addr }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-subheader>
          {{ $t('pay_usdt_token') }}
        </v-subheader>
        <v-select
          v-model="curreny_type"
          :items="curreny_types"
          filled
          dense
          rounded
          readonly
          no-data-text=""
          background-color="app_bg"
          hide-details="auto"
          disabled
        />

        <v-subheader>
          {{ $t('pay_usdt_chain') }}
        </v-subheader>
        <v-select
          v-model="chain"
          :items="chains"
          filled
          dense
          rounded
          readonly
          background-color="app_bg"
          hide-details="auto"
          disabled
        />

        <v-divider class="opacity-3 mt-4" />

        <v-card
          flat
          outline
        >
          <v-card-text class="text-center">
            <strong>{{ $t('pay_usdt_tip') }}</strong>
            <div class="d-flex align-center justify-center mt-3">
              1 USDT ≈ 
              <img
                width="16"
                height="16"
                src="../assets/img/icons/coin.svg"
                class="mx-1"
              >
              {{ u1_point_ratio }} {{ $t('fen') }}
            </div>
          </v-card-text>
        </v-card>

        <v-divider class="opacity-3" />

        <v-subheader>
          {{ $t('pay_shuoming') }}
        </v-subheader>
        <v-list
          dense
          class="text-body-2 opacity-3 pt-0"
        >
          <v-list-item
            style="min-height: 28px;"
          >
            <v-list-item-content class="py-0 my-0">
              {{ $t('pay_xiane') }}
            </v-list-item-content>
            <v-list-item-action class="py-0 my-0">
              >1 USDT
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-sheet>

      <v-btn
        block
        x-large
        rounded
        color="primary"
        dark
        class="mt-5"
        to="Home"
      >
        {{ $t('pay_usdt_done') }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      agentinfo: {},
      curreny_types: ['USDT'],
      curreny_type: 'USDT',
      chains: [
        'TRX (TRC20)',
      ],
      chain: 'TRX (TRC20)',
      erweima: '',
      u1_point_ratio: '',
      recharge_addr: '',
    }),

    computed: {
      
    },
    created() {

    },
    mounted() {
      this.myinfo()
      this.deposit()
    },
    methods: {
      myinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.myinfo(paramObj).then((response) => {
          if(response.code==200){
            this.agentinfo = response.data
            store.commit('agentinfo', this.agentinfo)
          }
        })
      },
      deposit() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.deposit(paramObj).then((response) => {
          if(response.code==200){
            this.erweima = response.data.erweima
            this.u1_point_ratio = response.data.u1_point_ratio
            this.recharge_addr = response.data.recharge_addr
          }
        })
      },
      doCopy: function (text) {
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  }
</script>